import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    token: String,
    receivedRedirectUri: String
  }

  connect() {
    this.linkHandler = Plaid.create(this.config())
    if (this.receivedRedirectUriValue) {
      this.open()
    }
  }

  open() {
    this.linkHandler.open()
  }

  config() {
    let stimulus = this;
    console.log(stimulus.tokenValue)
    let config = {
      // Pass the link_token generated in step 2.
      token: stimulus.tokenValue,
      onSuccess: async function(public_token, metadata) {
        await stimulus.createStripeToken(public_token, metadata.accounts[0].id)
      }
    }
    if (stimulus.receivedRedirectUriValue) {
      config.receivedRedirectUri = stimulus.receivedRedirectUriValue
    }

    return config
  }

  async createStripeToken(link_token, account_id) {
    window.dispatchEvent(new CustomEvent('start-loading'))
    let token = document.getElementsByName("csrf-token")[0].content;

    let data = {
      link_token: link_token,
      account_id: account_id
    }

    fetch('/plaid/callback', {
      method: 'POST',
      mode: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify(data)
    }).then(response => response.json()).then(data => {
      window.dispatchEvent(new CustomEvent('stop-loading'))
      window.dispatchEvent(new CustomEvent('dismiss-popups'))
      window.dispatchEvent(new CustomEvent("update-tabs", {
        detail: {
          source: 'plaid',
          hide_others: true,
          output: {
            plaid_bank_name: data.bank_name,
            plaid_last4: data.last4,
            plaid_account_holder_name: data.account_holder_name
          }
        }
      }))
    }).catch((error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent('stop-loading'))
      alert('An unexpected error occurred. Please try again. ')
    });
  }
}
