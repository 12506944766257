import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    publishableKey: String,
    nextPath: String,
    type: String
  }
  static targets = [
    'form',
    'formError',
    'submitButton',
    'routingNumber',
    'accountNumber',
    'accountHolderName',
    'accountHolderType',
    'cardInput'
  ]

  disconnect() {
    this.formTarget.removeEventListener('submit', this.bindedCallbackHandler);
    this.cardElement?.destroy;
  }

  connect() {
    let stimulus = this
    this.stripe = Stripe(this.publishableKeyValue)

    if (this.typeValue === 'card') {
      this.disableSubmitButton()

      let elements = this.stripe.elements()
      this.cardElement = elements.create('card', {
        style: {
          base: {
            fontFamily: 'Inter, sans-serif',
            mixBlendMode: 'normal',
            lineHeight: 1.4,
            letterSpacing: '0.4px',
            fontSize: '16px',
            '::placeholder': {
              color: 'rgba(0, 0, 0, 0.3)'
            },
            color: 'black'
          }
        }
      })
      this.cardElement.on('change', function(event) {
        if (event.complete) {
          // enable payment button
          console.log('credit card is good to go')
          stimulus.enableSubmitButton()
        } else if (event.error) {
          // show validation to customer
          console.log('bad credit card')
          stimulus.enableSubmitButton()
        }
      })
      this.cardElement.mount(this.cardInputTarget)
    }

    this.bindedCallbackHandler = this.callbackHandler.bind(this);
    this.formTarget.addEventListener('submit', this.bindedCallbackHandler);
  }

  callbackHandler(e) {
    e.preventDefault()
    this.disableSubmitButton()
    this.clearExistingErrors()

    console.log('submitting the form!')
    window.dispatchEvent(new CustomEvent('start-loading'))
    if (this.typeValue === 'bank_account') {
      this.fetchBankAccountToken().then((token) => {
        this.createStripeSource(token)
      }).catch(error => {
        // this.formErrorTarget.classList
        // do nothing
      })
    } else {
      this.fetchCreditCardToken().then((token) => {
        this.createStripeSource(token)
      })
    }
  }

  async fetchCreditCardToken() {
    const {token, error} = await this.stripe.createToken(this.cardElement)
    if (error) {
      this.displayError({
        param: 'card',
        message: error.message
      })
    } else {
      console.log(token)
      return token
    }
  }

  async fetchBankAccountToken() {
    let formData = this.serializeFormData(new FormData(this.formTarget))
    let defaults = {
      country: 'US',
      currency: 'usd',
    }
    let params = {
      ...defaults,
      ...formData
    }

    const {token, error} = await this.stripe.createToken('bank_account', params)
    if (error) {
      // handle error
      this.displayError(error)

      throw 'bad token'
    } else {
      // handle token
      return token
    }
  }

  async createStripeSource(token) {
    let csrfToken = document.getElementsByName("csrf-token")[0].content

    fetch('/stripe/callback', {
      method: 'POST',
      mode: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({
        token: token.id
      })
    }).then(response => response.json()).then(data => {
      window.dispatchEvent(new CustomEvent('stop-loading'))
      if (data.error) {
        this.displayError({
          param: 'card',
          message: data.error
        })
      } else {
        window.dispatchEvent(new CustomEvent('dismiss-popups'))
        if (this.typeValue === 'card') {
          window.dispatchEvent(new CustomEvent('update-tabs', {
            detail: {
              source: 'card',
              hide_others: true,
              output: {
                card_brand: token.card.brand,
                card_last4: token.card.last4,
                card_exp_month: String(token.card.exp_month).padStart(2, "0"),
                card_exp_year: String(token.card.exp_year % 100)
              }
            }
          }))
        } else {
          window.dispatchEvent(new CustomEvent('update-tabs', {
            detail: {
              source: 'bank',
              hide_others: true,
              output: {
                bank_name: token.bank_account.bank_name,
                bank_account_holder_name: token.bank_account.account_holder_name,
                bank_last4: token.bank_account.last4,
              }
            }
          }))
        }
      }
    }).catch((error) => {
      window.dispatchEvent(new CustomEvent('stop-loading'))
      console.log(error)
      alert('An unexpected error occurred. Please try again. ')
      this.enableSubmitButton()
    })
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true
  }

  enableSubmitButton() {
    this.submitButtonTarget.removeAttribute("disabled")
  }

  serializeFormData(formData) {
    let obj = {}
    for (let key of formData.keys()) {
      obj[key] = formData.get(key)
    }
    return obj
  }

  displayError(error) {
    window.dispatchEvent(new CustomEvent('stop-loading'))
    console.log({error})
    const match = /\[(.*?)\]$/.exec(error.param)
    let feedback = this.createFeedbackElement(error.message)
    if (match && match[1]) {
      const camelCased = match[1].replace(/_(\w)/g, (word, index) => word[1].toUpperCase())
      const target = this[camelCased + 'Target']
      if (target) {
        target.parentNode.classList.add('is-invalid')
        target.parentNode.insertAdjacentElement('afterend', feedback)
      }
    } else {
      this.formTarget.insertAdjacentElement('afterbegin', feedback)
    }
    this.enableSubmitButton()
  }

  clearExistingErrors() {
    document.querySelectorAll('.is-invalid').forEach(element => {
      element.classList.remove('is-invalid')
    })
    document.querySelectorAll('.invalid-feedback').forEach(element => {
      element.remove()
    })
  }

  createFeedbackElement(message) {
    let feedback = document.createElement('div')
    feedback.classList.add('invalid-feedback')
    feedback.innerHTML = message

    return feedback
  }
}
